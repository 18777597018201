/* top | right | bottom | left */
@font-face {
  font-family: 'Montserrat';
  src: url('/public/fonts/Montserrat-Regular.ttf') format('truetype');
  font-family: 'Inter';
  src: url('/public/fonts/Inter-Regular.ttf') format('truetype');
}

* {
  font-size: 100%;
  font-family: Montserrat;
  font-style: normal;
  line-height: normal;
  outline: none !important;
}
body {
  background-repeat: round;
  background-size: contain;
  font-size: 100%;
  font-family: Montserrat !important;
  font-size: 14px !important;
  font-style: normal;
  line-height: normal;
  outline: none !important;
}
.ui.container {
  width: 90%;
  padding-left: 2px;
  padding-top: 2px;
  min-height: 100vh;
}
.mainPageGrid {
  position: relative !important;
  margin-left: 70px !important;
  padding: 10px !important;
  width: 1300px !important;
  outline: none !important;
}
.dashboardPage {
  margin-left: 140px !important;
  margin-right: 140px !important;
  width: 1130px !important;
}
.mainTable {
  width: 100% !important;
}
i.icon {
  outline: none !important;
}

/*Dash Board*/
.selectCompanyBox .ui.table tr td {
  border-top: 0px !important;
}
.rowTop {
  border-radius: 6px;
  border: 1px solid var(--Gray-3, #e7e8e9) !important;
  /* box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25); */

  height: 45px;
  background: var(--Success, #d4ead4);

  padding: 7px !important;
  margin: 20px 10px 5px 15px !important;
}
.rowTop > a {
  color: var(--Black, #101820);
  font-size: 14px;
  font-weight: 600;
  display: flex !important;
  align-items: center !important;
}
.rowTop img {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}
.rowTop .dateSpan {
  margin-left: 10px !important;
  font-weight: 400 !important;
}

.rowNegative {
  background: var(--Error, #f7e4e4) !important;
}
.rowTop.rowNegative a {
  color: var(--Error, #ff2727);
}

/*End Dash Board*/

.RegCompanyGrid {
  border-radius: 20px;
  background: #002437;
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.25);
  width: 760px !important;
  height: 650px !important;
  /* top | right | bottom | left */
  padding: 10px 10px 10px 30px !important;
}



.dashBoardSegment {
  border-radius: 14px;
  border: 1px solid var(--Gray-3, #e7e8e9);
  background: var(--White, #fff);
  box-shadow: 0px 3px 30px 0px rgba(0, 0, 0, 0.06);
  padding-left: 27px !important;
}
.dashBoardSegment.R1C1 {
  width: 650px !important;
  height: 187px !important;
  padding-right: 39px !important;
}
.dashBoardSegment.R1C2 {
  width: 430px !important;
  height: 187px !important;
  padding-right: 25px !important;
  position: absolute !important;
  right: 0 !important;
}
.dashBoardSegment.R2C1-2 {
  /* top | right | bottom | left */
  padding: 30px 42px 0px 42px !important;
  width: 254px;
  height: 296px;
}
.dashBoardSegment.R2C3 {
  width: 526px;
  height: 296px;
  padding: 15px !important;
  margin: 0 !important;
  position: absolute;
  right: 0;
}

.dashBoardSegmentItem {
  width: 182px !important;
  height: 95px;
  border-radius: 6px;
  background: var(--Grey-4, #f3f6f9);
  padding-left: 18px !important;
  padding-bottom: 14px !important;
  padding-top: 14px !important;

  display: flex !important;
  align-items: center !important;
  flex-wrap: wrap !important;
}

.dashBoardSegmentItem.current {
  border: 1px solid var(--Error, #ff2727);
}
.dashBoardItemImg {
  width: 18px;
  height: 18px;
  margin-right: 7px !important;
}
.dashBoardSegmentTitleFail {
  color: var(--Error, #ff2727);
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  display: flex !important;
  align-items: center !important;
}

.dashBoardSegmentTitleSuccess {
  color: var(--Success, #3dc03a);
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  display: flex !important;
  align-items: center !important;
}
.dashBoardSegmentLabel {
  color: var(--Gray-1, #7a7a7a);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;

  margin-bottom: 5px;
}
.dashBoardSegmentContent {
  color: var(--Black, #101820);
  font-family: Montserrat;
  font-size: 26px;
  font-style: normal;
  font-weight: 600;
  line-height: 36px;
  width: 80px !important;
  margin-top: 10px;
}
.dashBoardSegmentItem.current > .dashBoardSegmentContent {
  color: var(--Error, #ff2727);
  width: 120px !important;
}
.legend {
  color: var(--Black, #101820);
  padding: 0 !important;
  font-family: Inter;
  font-size: 16px;
  font-weight: 700;
  line-height: 20px; /* 125% */
}
.circle,
.ui.icon.button > .icon {
  line-height: normal !important;
  border: none !important;
  margin-right: 5px !important;
}
.statisticsCircle {
  width: 150px !important;
  height: 150px !important;
  border: 1px solid var(--Gray-2, #d1d1d1) !important;
  border-radius: 75px !important;
  color: var(--Black, #101820);
  text-align: center;
  font-size: 26px;
  font-weight: 600;
  line-height: 36px;
  padding: 55px;
  position: absolute !important;
  bottom: 30px;
}
.statisticsCircle.blue {
  border: 1px solid var(--Blue-2, #0069a7) !important;
  color: var(--Blue-2, #0069a7);
}
.ui.icon.button > .icon {
  line-height: normal !important;
  border: none !important;
  margin-right: 5px !important;
}

.assetTitle {
  height: 70px !important;
  width: 300px !important;
  border-radius: 6px !important;
  background: var(--Grey-4, #f3f6f9) !important;
  border: 1px solid var(--Grey-4, #f3f6f9) !important;
  pointer-events: none;
  display: flex !important;
  align-items: center !important;
  font-size: 16px;
}
.react-datepicker__close-icon {
  right: 50px !important;
}
.span {
  font-size: 24px;
  margin-bottom: 10px;
}
.areaInput .formTextInput > input {
  height: 85px !important;
}
/*Left Menu Css*/
.vertical.left.fixed.menu {
  background: linear-gradient(180deg, #071d29 0%, #0069a7 100%) !important;
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.25) !important;
  width: 230px;
  height: 100%;
  font-size: 16px;
  color: white;
  font-family: unset !important;
}

.menuLogoImg {
  width: 148px !important;
  height: 36px !important;
  /* top | right | bottom | left */
  margin: 47px 46px 34px 36px !important;
}

.companySelect {
  height: 44px !important;
  width: 182px !important;

  color: var(--Gray-3, #e7e8e9) !important;
  font-size: 12px !important;
  font-weight: 500 !important;

  display: flex !important;
  align-items: center !important;
  border-bottom: 1px solid #315e72 !important;
  /* padding: 15px 15px 15px 2px !important; */
  padding: 10px 28px 10px 10px !important;
  /* top | right | bottom | left */
  margin: 0 26px 24px 22px !important;
}
.companySelectImg {
  width: 9px !important;
  position: absolute !important;
  right: 2px !important;
}

.selectCompanyBox {
  height: 370px;
  width: 260px;
  padding: 10px !important;
  position: absolute;
  top: 180px;
  left: 40px;
  display: block;
  border-radius: 6px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  background: #fff;
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.1);
  font-size: 16px;
  z-index: 9999;
}

.menuItem {
  /* top | right | bottom | left */
  padding: 10px 28px 10px 10px !important;
  /* margin: 15px 15px 15px 10px !important; */
  margin: 0 26px 10px 22px !important;
  line-height: 24px !important;
  height: 44px !important;
}

.menuItemText {
  color: #f3f6f9;
  text-align: left !important;
  padding-left: 35px !important;

  font-size: 14px !important;
  font-weight: 500 !important;
}
.active.item.menuItem {
  border-radius: 6px !important;
  background: var(--blue-1, #0082ca) !important;
  height: 44px !important;
  width: 182px !important;
}

.menuItem:hover {
  height: 44px !important;
  width: 182px !important;
}
.menuItem .menuImage {
  z-index: 1 !important;
  display: inline-block !important;
  margin-right: 3px;
}

.menuItem .menuImage.alterImage {
  z-index: 2 !important;
}
a.active.item.menuItem .image-container .menuImage {
  opacity: 0 !important;
}
a.active.item.menuItem .image-container .alterImage {
  opacity: 1 !important;
}
.ui.circular.label.initialsLabel {
  width: 33px !important;
  height: 33px !important;
  color: var(--Grey-4, #f3f6f9) !important;
  text-align: center !important;
  font-size: 11px !important;
  font-weight: 700 !important;
  display: flex !important;
  align-items: center !important;
  padding: 9px !important;
  margin-bottom: 5px !important;
}
.scrolblTable {
  height: 200px;
  overflow-x: hidden;
  overflow-y: scroll;
}
.scrollForm {
  padding-left: 0 !important;
  margin-top: 20px !important;
  overflow: auto;
  height: 500px;
}
.scrollForm > .row.formFieldRow {
  height: 83px !important;
}
.formFieldRow {
  height: 83px !important;
}

.menuBottom {
  /* top | right | bottom | left */
  position: absolute !important;
  bottom: 0;
  line-height: 25px;

  height: 135px;
  width: 195px;
  margin: 10px 18px 10px 18px !important;
  padding: 10px !important;
  cursor: pointer !important;
  display: grid !important;
}

.menuBottom.selected {
  border-radius: 6px !important;
  border: 1px solid var(--Blue-2, #0082ca) !important;
}

.menuBottomText {
  color: var(--Gray-3, #e7e8e9);
  font-size: 12px;
  font-weight: 700;
}
.menuBottomText1 {
  color: var(--Gray-3, #e7e8e9);
  font-size: 12px;
  font-weight: 400;
}
.menuBottomText2 {
  color: var(--Blue-6, #96daea);
  font-size: 12px;
  font-weight: 600;
}
.menuBottomText3 {
  color: var(--Blue-6, #96daea);
  font-family: Inter !important;
  font-size: 12px;
  font-weight: 400;
}
.menuBottomText4 {
  color: var(--Gray-3, #e7e8e9);
  font-size: 12px;
  font-weight: 400;
  font-style: italic !important;
}

.techMenu {
  z-index: 9999;
  height: 320px !important;
  width: 260px !important;
  border-radius: 6px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  background: #fff;
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.1);
  position: absolute;
  left: 160px !important;
  bottom: 20px !important;
}
.simpleTechMenu {
  height: 180px !important;
}
.techMenu .menu {
  /* top | right | bottom | left */
  padding: 18px 5px 5px 19px !important;
}
.techMenu .menu.exitMenu {
  margin-top: 10px;
  bottom: 10px;
  right: 0;
  position: absolute;
  border-top: 1px solid #e7e8e9 !important;
}
.ui.secondary.inverted.menu a.item.techMenuItem {
  cursor: pointer !important;
  height: 37px !important;
  width: 219px !important;
  padding: 9px !important;
  text-wrap: nowrap !important;
  color: var(--dark-grey, #7a7a7a) !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  display: flex !important;
  align-items: center;
  margin: 9px !important;
}
.item.techMenuItem.active,
.item.techMenuItem:hover {
  background: var(--Grey-4, #f3f6f9) !important;
  border-radius: 6px !important;
  width: 219px !important;
}
.item.techMenuItem img {
  vertical-align: middle !important;
  margin-right: 10px;
}
/*---------------------*/

.thinField {
  height: 45px !important;
}
.ui.input.loginField > input {
  font-family: Montserrat !important;
  font-size: 16px !important;
  font-weight: 400 !important;
  color: 1c1c1c !important;
  border-style: none !important;
  -webkit-autofill,
  -webkit-autofill:hover,
  -webkit-autofill:focus,
  -webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
  }
}

.loginField.error {
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  border: 1px solid #f00;
}

.loginField.error > input {
  border: 1px solid #ff2727 !important;
  background: #fff !important;
  color: var(--Black, #101820) !important;
}

.loginField.required > .ui.label::after {
  content: ' * ' !important;
  color: red !important;
  font-weight: 800 !important;
}
.errorLbl {
  color: #ff2727 !important;
  font-size: 12px !important;
  font-weight: 400 !important;
  border: 0 !important;
  background: transparent !important;
  padding: 0 !important;
  margin: 0 !important;
}
.formTextInput.invertedCollor > .ui.label {
  color: var(--Grey-4, #f3f6f9) !important;
  font-size: 16px !important;
  font-weight: 400 !important;
  margin-bottom: 5px !important;
}




/*---------------------*/
/* Start  Forms Css*/
.companiesList {
  height: 340px;
  width: 260px;
  position: relative;
  top:30px;
  left: 15px;

  display: block;
  border-radius: 6px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  background: #fff;
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.1);
  font-size: 16px;
  z-index: 1;
}

.formSubTitle {
  color: #1c1c1c;
  font-size: 18px;
  font-weight: 600;
  border-style: none !important;
}

.formSubTitleImg {
  display: inline-block !important;
  /* margin-left: 10px !important; */
  margin-right: 10px !important;
  width: 26px;
  height: 26px;
  color: #1c1b1f;
}
.formSelectInput {
  width: 300px !important;
  height: 42px !important;
}
.selectFileInput {
  border-radius: 6px !important;
  background: var(--Grey-4, #f3f6f9) !important;
  border: 1px solid var(--Grey-4, #f3f6f9) !important;
  width: 250px !important;
  height: 42px !important;
}
.formSelectInput > input {
  border-radius: 6px !important;
  background: var(--Grey-4, #f3f6f9) !important;
  border: 1px solid var(--Grey-4, #f3f6f9) !important;
  pointer-events: none;
  font-family: 'Montserrat' !important;
}
.formSelectInput.error > input {
  border: 1px solid #ff2727 !important;
} 
.searchText {
  border-radius: 100px;
  border: 1px solid var(--Black, #d9d9db);
  background: var(--Grey, #eeeef1);
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.1);
  width: 90% !important;
  height: 46px !important;
}
.ui.input.searchText > input {
  border: none !important;
  background: transparent !important;
  padding-left: 35px !important;
}
.ui.input.searchText.focus > input,
.ui.input.searchText > input:focus {
  border: none !important;
  border-radius: 100px !important;
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.1) !important;
  background: var(--Grey, #eeeef1) !important;
}
.ui.toggle.checkbox input:checked ~ label:before {
  background: var(--Blue-5, #54c8e8) !important;
}
.searchText.error {
  border: 1px solid #ff2727 !important;
  color: var(--Black, #101820) !important;
}

.ui.toggle.checkbox input:checked ~ label:after {
  height: 25px;
  width: 25px;
  top: -2px;
  background: var(--Blue-3, #0082ca);
}

.formSelectInput > .ui.dropdown {
  width: 300px !important;
  height: 42px !important;
  border-radius: 6px !important;
  background: var(--Grey-4, #f3f6f9) !important;
  border: 1px solid var(--Grey-4, #f3f6f9) !important;
}
.formSelectInput > .ui.dropdown > .text {
  width: 250px !important;
  font-weight: 400;
}
.formTextInput {
  width: 300px !important;
  display: block !important;
}
.formTextInput > input {
  width: 300px !important;
  height: 42px !important;
  margin-bottom: 0 !important;

  border-radius: 6px !important;
  border: 1px solid rgba(0, 0, 0, 0.1) !important;

  color: #1c1c1c !important;
  font-size: 16px !important;
  font-weight: 400 !important;
}

.formTextInput.error > input {
  border: 1px solid #ff2727 !important;
  background: #fff !important;
  color: var(--Black, #101820) !important;
}

.formTextInput > .ui.label,
.formCheckBoxLabel {
  color: #1c1c1c;
  font-size: 14px;
  font-weight: 600;
  background: none;
  display: block !important;
  height: 27px;
  margin-bottom: 5px !important;
}
.formPanel {
  z-index: 9999;
  border-radius: 6px;
  border: 1px solid rgba(122, 122, 122, 0.3);
  background: #fff;
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.1);
  width: 500px !important;
  position: fixed !important;
  top: 20px !important;
  right: 20px !important;
  padding: 30px !important;
  margin: 0 !important;
  height: 720px;
  display: flex;
  flex-direction: column !important;
  flex: 1;
}

.formTextInput > input:focus {
  color: #0082ca;
  border: 1px solid var(--Blue-1, #0082ca) !important;
  background: #fff !important;
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.15) !important;
}

.formInput.required > .ui.label::after,
.formTextInput.required > .ui.label::after {
  content: '*';
  color: red;
  font-size: 2vh;
  font-weight: 800;
}
.payAttention
{
  color: red;
}
.formTimerInput {
  width: 350px !important;
  height: 42px !important;
  border-radius: 6px !important;
  border: 1px solid rgba(0, 0, 0, 0.1) !important;
  color: #1c1c1c !important;
  font-size: 16px !important;
  font-weight: 400 !important;
}

/*---------------------*/
/* Start  Controllers Css*/

.thAdminTbl {
  padding: 0 !important;
  vertical-align: middle !important;
  text-align: center  !important;
}
.thAdminTbl a {
  display: inline-block  !important;
}
/*---------------------- */

.heightRow {
  height: 150px !important;
  border-bottom: 1px solid #e7e8e9 !important;
}
.file-input-label {
  font-size: 16px;
  font-weight: bold;
  color: #333;
  cursor: pointer;
  padding: 8px;
  min-width: 250px !important;
  height: 42px !important;
  border-radius: 6px !important;
  background: var(--Grey-4, #f3f6f9) !important;
  border: 1px solid var(--Grey-4, #f3f6f9) !important;
  display: flex;
  margin-left: 14px !important;
}

.file-input-label label {
  display: flex !important;
}

.file-inputBox {
  display: flex !important;
  justify-content: left !important;
  align-items: left !important;
  margin-top: 15px;
  margin-bottom: 15px;
  border-bottom: 1px solid #e7e8e9 !important;
}

/*Start Justify type Css*/

.justifyRight {
  display: flex !important;
  justify-content: right !important;
  align-items: right !important;
}

/*---------------------*/
/*Start Image Types Css*/
.smallImg {
  width: 24px;
  height: 24px;
  margin-right: 10px !important;
}

.closeWindow {
  position: absolute !important;
  right: -2px !important;
  top: 5px !important;
  outline: none !important;
  z-index: 9999 !important;
  cursor: pointer; 
}

/*----------------------*/
/*Start Title Types  Css */
.pageTitle {
  font-size: 22px;
  color: var(--blue-1, #0082ca);
  font-weight: bold;
}
.pageTitleColumn {
  margin-top: 20px !important;
  margin-bottom: 30px !important;
}
.dataHeader {
  line-height: 30px;
  border-bottom: 1px solid #e7e8e9 !important;
  height: 80px !important;
}
.dataTitle {
  color: var(--Black, #101820);
  font-size: 22px;
  font-weight: 600;
}

.statisticTitle {
  color: var(--Black, #101820);
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
}
.tableUpHeader td {
  color: var(--black, #101820);
  font-size: 12px !important;
  font-weight: 600 !important;
  text-transform: uppercase !important;
  flex-direction: column !important;
}

.pageSubTitle {
  color: var(--black, #101820);
  font-size: 12px !important;
  font-weight: 600 !important;
  text-transform: uppercase !important;
  display: flex !important;
  flex-direction: column !important;
}
/*----------------------*/
/*-----Start Buttons Css */
.filterBox {
  display: flex !important;
  position: absolute;
  right: 2px !important;
}
.filterBox.admin {
  top: -60px !important;
}
.filterButton {
  width: 122px !important;
  height: 36px !important;
  padding-bottom: 5px !important;
  padding-top: 5px !important;
  border-radius: 100px !important;
  border: 1px solid #e4e4e4 !important;

  color: #1c1c1c !important;
  font-size: 13px !important;
  font-weight: 400 !important;
  background: none !important;
}
.filterButton span {
  display: flex !important;
}
.ui.table thead {
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 2 !important;
}
.filterLeftMarker {
  width: 122px !important;
  height: 36px !important;
  flex-shrink: 0 !important;
  color: #1c1c1c !important;
  font-size: 13px !important;
  font-weight: 400 !important;
  line-height: 18px !important;
  display: flex !important;
  align-items: center !important;
  background: none !important;
  padding-left: 9px !important;
  padding-right: 9px !important;
}

.filtesImg {
  margin-right: 5px !important;
  margin-left: 5px !important;
  padding: 4px !important;
}
.filterButton img,
.filterLeftMarker img {
  vertical-align: middle;
  padding-right: 5px;
  padding-left: 8px;
}
.filterButton:hover {
  border: 1px solid #1c1c1c !important;
  background: #f3f6f9 !important;
}

.filterActivated {
  border: 1px solid #e4e4e4 !important;
  background: #1c1c1c !important;
  font-weight: 600 !important;
  color: white !important;
  margin-left: 20px !important;
}

.filterActivated .ui.image {
  filter: invert(100%) sepia(5%) saturate(0%) hue-rotate(338deg)
    brightness(104%) contrast(108%) !important;
}
/*----------------------*/
/*Start Settings   Css */
.tabContainer {
  justify-content: left !important;
  border-block-color: #e4e4e4 !important;
  border-bottom-style: solid !important;
  padding-bottom: 2px !important;
  border-bottom-width: 1px !important;
  margin-top: 30px !important;
}
.tabContainer.menu a.item {
  color: #1c1c1c !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  padding: 15px 5px 15px 5px !important;

  margin-left: 4px !important;
  margin-right: 30px !important;
}
.tabContainer.menu a.active.item {
  color: var(--Blue-1, #0082ca) !important;
  font-weight: 600 !important;
  border-bottom: 3px solid #0082ca !important;
}

i.icon.lock:before {
  position: absolute;
  top: 70% !important;
}

.formCheckBox {
  background-color: #fff;
  z-index: 0;
}
.formCheckBox input:checked ~ .box:after,
.formCheckBox input:checked ~ label:after {
  background-color: var(--blue-1, #0082ca) !important;
  color: white !important;
  font-weight: 100 !important;
  font-size: 12px !important;
}

.applyBtn {
  width: 112px !important;
  height: 40px !important;
  border-radius: 6px !important;
  background: var(--blue-1, #0082ca) !important;

  color: #fff !important;
  font-size: 14px !important;
  font-weight: 600 !important;
  line-height: 18px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center;
}
.applyBtn img {
  order: -1;
  margin-right: 5px;
}

.applyBtn.sysAction {
  background: transparent !important;
  border: 1px solid var(--Gray-2, #d1d1d1) !important;
  color: var(--Blue-3, #0082ca) !important;
}

.applyBtnS {
  width: 86px !important;
  height: 38px !important;
  border-radius: 6px !important;
  background: var(--Blue-3, #0082ca) !important;
  color: var(--White, #fff) !important;
  font-size: 14px !important;
  font-weight: 600 !important;
  line-height: 18px !important;
  text-align: center !important;
}
.applyBtn.tabFormBtn {
  position: absolute !important;
  top: -120px !important;
  right: 120px !important;
}

.verificationBox{

  position: absolute  !important;
  bottom: 10px  !important;
  left: 20px  !important;
 padding: 20px  !important;

 color: var(--Black, #101820)  !important;
 font-size: 16px  !important;;
 font-weight: 600  !important;

border: 1px solid var(--Gray-3, #E7E8E9)  !important; 
background: var(--Grey-4, #F3F6F9)  !important;
box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.10)  !important;

width: 480px;
 height: 170px;
}
.verificationDiv {
display:flex !important;
margin-top: 20px  !important;
}

.verificationBox .label  {
  color: #ff2727 !important;
  font-size: 16px !important;
  align-items: center;
  background: transparent !important;
  border: none !important;
  padding-top: 10px;
  padding-left: 0 !important;
}
 .modelCloseWindow{
  position: absolute  !important;
  right: 30px  !important;
  cursor: pointer  !important;
}
.modalCenter > .closeWindow{
  top :20px !important;
  right: 20px !important;
  cursor: pointer; 
}
.resetBtn {
  width: 112px !important;
  height: 40px !important;
  border: none !important;
  color: var(--Gray-1, #7a7a7a);
  font-size: 14px !important;
  font-weight: 600 !important;
  line-height: 18px !important;
  text-align: center !important;
}
.resetBtnS {
  width: 86px !important;
  height: 38px !important;
  border: none !important;
  color: var(--Gray-1, #7a7a7a);
  font-size: 14px !important;
  font-weight: 600 !important;
  line-height: 18px !important;
  text-align: center !important;
}
.resetBtnS:hover,
.resetBtn:hover {
  border-radius: 6px !important;
  border: 1px solid #1c1c1c !important;
  background: #f3f6f9 !important;
  cursor: pointer !important;
}
/*----Download Page Css------ */
.resourcesGrid {
  padding: 40px !important;
}
.recharts-legend-wrapper {
  top: 10px !important;
}
.resourceBox {
  width: 350px !important;
  height: 364px !important;
  border-radius: 14px;
  box-shadow: 0px 3px 30px 0px rgba(0, 0, 0, 0.06);
  border: 1px solid #e4e4e4;
  padding: 5px !important;
  margin-right: 20px !important;
}
.rBox1 {
  background: #0082ca;
  background: url('/public/img/Res1.jpg');
  background-repeat: no-repeat;
  background-size: cover;
}
.rBox2 {
  background: #0082ca;
  background: url('/public/img/Res2.jpg');
  background-repeat: no-repeat;
  background-size: cover;
}
.rBox3 {
  background: #0082ca;
  background: url('/public/img/Res3.jpg');
  background-repeat: no-repeat;
  background-size: cover;
}
.rBox4 {
  background: #0082ca;
  background: url('/public/img/Res4.jpg');
  background-repeat: no-repeat;
  background-size: cover;
}

/* .rBox2 {
  background: linear-gradient(
      10deg,
      rgba(0, 170, 255, 0) 1.89%,
      rgba(0, 128, 255, 0) 1.89%,
      #05212f 63.98%,
      #101820 73.34%
    ),
    var(--blue-1, #0082ca) !important;
} */

.resGrid {
  justify-content: center !important;
  height: 100% !important;
}
.resourceTitle {
  color: #f3f6f9 !important;
  text-align: center !important;
  font-size: 1.5rem !important;
  font-weight: 600 !important;
  text-transform: capitalize !important;
  line-height: normal;
}

.resourceSubTitle {
  color: #f3f6f9;
  text-align: center;
  font-size: 14px;
  font-weight: 600;
  margin-top: 20px !important;
}
.resourceRemark {
  color: var(--Grey, #f3f6f9);
  text-align: center;
  font-size: 12px;
  font-weight: 400;
  margin-top: -10px !important;
}
.resourceImg {
  margin-bottom: 2vh !important;
  margin-top: 2vh !important;
}
.resourceLabel {
  color: #1c1c1c;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
}
.lock.icon {
  height: 60% !important;
}
.subLabel {
  color: #1c1c1c;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  padding-left: 4px;
}
.fileInput{
  display: flex  !important;
  align-items: center  !important;
  justify-content: center  !important;
}
.ui.grid > .row > [class*='two wide'].column.imgColumn {
  padding-right: 0 !important;
  width: 25px !important;
}
.resourceSmallLabel {
  color: var(--Dark-Grey, #7a7a7a);
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
}

.resourceTabMenu {
  justify-content: left !important;
  padding-bottom: 2px !important;
  border-bottom-width: 0px !important;
}
.resourceTabMenu > a.item {
  color: #1c1c1c !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  padding: 15px 5px 15px 5px !important;

  margin-left: 4px !important;
}
.resourceTabMenu > a.active.item {
  color: var(--Black, #101820) !important;
  font-weight: 600 !important;
  font-style: normal;
  font-size: 1rem;
  border-bottom: 3px solid #101820 !important;
}
.resourceTab {
  justify-content: left !important;
}
.downloadBtn {
  width: 112px;
  height: 40px;
  flex-shrink: 0;
  border-radius: 6px;
  background: var(--Blue-3, #0082ca) !important;
  color: var(--White, #fff) !important;
  font-size: 14px;
  font-weight: 600;
  line-height: 18px;
}

/*----End Download Page Css------ */
.miniImg {
  height: 9px !important;
}

.addFormBox {
  z-index: 3 !important;
  position: fixed !important;
  top:90px !important;
  left: 250px !important;
  width: 545px;
  height: 700px  !important;
}
.addForm {
padding: 60px !important;
}
.activeLinkRow {
  border-top: 1px solid rgba(0, 0, 0, 0.1) !important;
  padding-top: 20px !important;
  position: absolute !important;
  bottom: 20px;
}

.activeLink {
  color: var(--Blue-1, #0082ca) !important;
  font-size: 14px !important;
  font-weight: 600 !important;
  line-height: 18px !important;
  display: block  !important;
  text-decoration:  underline;
  font-weight: bold !important;
  cursor: pointer;
}

.downloadLink {
  color:  var(--Success, #3dc03a) !important;
  font-size: 14px !important;
  font-weight: 600 !important;
  display: block  !important;
  text-decoration:  underline;
  font-weight: bold !important;
  cursor: pointer;
  padding-left: 10px  !important;
}

.filterPanel {
  z-index: 9999;
  border-radius: 6px;
  border: 1px solid rgba(122, 122, 122, 0.3);
  background: #fff;
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.1);
  width: 500px !important;
  position: absolute !important;
  top: 0 !important;
  right: 0;
  padding: 30px !important;
  margin: 0 !important;
  height: 750px;
  display: flex;
  flex-direction: column !important;
  flex: 1;
}
.filterPanel > .row {
  height: 50px !important;
  padding-top: 0 !important;
  padding-bottom: 5px !important;
}

.filterPanel > .row.filterTitleRow {
  height: 55px !important;
  padding-top: 14px !important;
}
.filterPanel > .row > .column {
  padding: 0 !important;
}
.filterPanel.small {
  height: 500px;
  top: -40px !important;
}
.small > .row {
  height: 45px !important;
  padding-top: 0 !important;
  margin-top: 5px !important;
}

.filtersOption {
  height: 35px !important;
  min-width: 80px !important;
  border-radius: 100px !important;
  border: 1px solid rgba(122, 122, 122, 0.3) !important;
  color: var(--dark-grey, #7a7a7a) !important;
  font-size: 13px !important;
  font-weight: 400 !important;
  line-height: 18px !important;
  background: white !important;
  /* top | right | bottom | left */
  padding: 6px 12px 6px 12px !important;
}
.filtersOption.selected,
.filtersOption:active,
.filtersOption:hover {
  color: var(--black, #101820) !important;
  background: var(--grey, #f3f6f9) !important;
  border: 1px solid var(--black, #101820) !important;
  font-size: 14px !important;
}

.buttonsRow {
  position: absolute !important;
  bottom: 30px !important;
}
.panelButtonsRow {
  position: absolute !important;
  bottom: 0 !important;
  left: 0 !important;
  border-radius: 6px;
  border: 1px solid var(--Gray-3, #e7e8e9);
  background: var(--White, #fff);
  height: 60px;
}

.recharts-wrapper {
  margin: 0 !important;
  padding: 0 !important;
}

.attackedTitle {
  color: #c03a3a;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
}

.cutText {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: 1;
  text-overflow: ellipsis;
}
.cutText:hover {
  -webkit-line-clamp: unset !important;
}
.tableRow {
  height: 62px !important;
}
.tableRow td {
  vertical-align: middle;
}
.tableRow:hover {
  border-radius: 6px !important;
  background: var(--Grey-4, #f3f6f9) !important;
}
.tableRow:hover td.blank {
  background: white !important;
}

.ui.table tr td.blank {
  border: 0 !important;
}

.ui.table thead th.blank {
  border: 0 !important;
  background: none;
}
.hidenIcon {
  visibility: hidden !important;
}
.tableRow:hover .hidenIcon {
  visibility: visible !important;
}
.mainPageGrid .ui.bottom.attached.segment.active.tab {
  display: block !important;
}
.textSearchBox {
  border: 1px solid rgba(0, 0, 0, 0.1);
  position: absolute;
  padding-top: 0px !important;
  z-index: 9999;
  top: 80px;
  height: 400px;
  width: 400px;
  left: 25px;
}
.textSearchBox .row {
  padding-top: 0px !important;
}
.textSearchBox table {
  display: block !important;
}

.responseMessage {
  z-index: 5 !important;
  border-radius: 6px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  height: 45px;
  
  font-size: 14px;
  font-weight: 600;

  position: fixed;
  bottom: 20px;
  left: 550px;
  max-width: 400px;
  width: 100%;
  display: flex;
  align-items: center;
}
.responseMessage.success {
  background: var(--Success, #3dc03a);
  color: var(--Grey-4, #f3f6f9) !important;
}
.responseMessage.fail {
  background: var(--Error, #ff2727);
  color: var(--Grey-4, #f3f6f9) !important;
}

.responseMessage.success > p::before {
  content: url('/public/img/check_small.svg');
  margin: 10px;
  vertical-align: middle;
}
.responseMessage.fail > p::before {
  content: url('/public/img/err_small.svg');
  margin: 10px;
  vertical-align: middle;
}
.topForm {
  position: absolute !important;
  top: 0 !important;
}
.notificationCheck {
  margin-top: 0;
}
.percentTitle {
  color: #101820;
  font-weight: bold;
  position: absolute;  
  right: -15px;
}
.chartSubTitle {
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  width: 200px;
  justify-content: space-between;  
  padding-bottom: 10px;     
}

.chartSubTitle::marker {
  font-size: 24px;
}
.statusRecoveryFinished,
.statusBackupFinished,
.statusGeneratedSN,
.statusBackupSuccess,
.chartSubTitle.statusSuccess {
  color: #3dc03a;
}
.statusWarning,
.chartSubTitle.statusWarning {
  color: #ffaa07;
}
.statusRecoveryCancelled,
.statusBackupCancelled,
.statusRegistrationFailed,
.statusCriticalError,
.chartSubTitle.statusCriticalError {
  color: #ff2727;
}
.statusRegistered,
.statusNoBackupOccurred,
.chartSubTitle.statusNoBackupOccurred {
  color: #0082ca;
}
.statusRecoveryStarted,
.statusBackupStarted,
.statusRegistered,
.statusPublished,
.chartSubTitle.statusRegistered {
  color: gray;
}
.addInfo {
  background: var(--grey, #f3f6f9) !important;
  font-size: 12px;
}
.calendarImput {
  width: 172px !important;
  height: 38px !important;
  border-radius: 100px !important;
  border: 1px solid rgba(122, 122, 122, 0.3) !important;
  padding: 10px !important;
  color: var(--dark-grey, #7a7a7a) !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  line-height: 18px !important;

  background-image: url('/public/img/calendar_month.svg');
  background-position: calc(5px + 0.5em) center !important;
  background-repeat: no-repeat;
  padding-left: 40px !important;
}
.filtersTitle {
  color: var(--black, #101820);
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  vertical-align: -webkit-baseline-middle !important;
}
.filtersLabel {
  color: var(--black, #101820) !important;
  font-family: Montserrat;
  font-size: 16px !important;
  font-weight: 400 !important;
  line-height: 18px !important;
  border-style: none !important;
}

.ui.table tr td.statusSuccess {
  position: relative;
  color: #3dc03a;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
}

.ui.table tr td.statusSuccess::before {
  display: inline-block;
  width: 10px;
  height: 10px;
  margin-right: 5px;
}

.ui.table tr td.statusErr {
  position: relative;
  color: #ff2727;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
}

.ui.table tr td.statusErr::before {
  display: inline-block;
  width: 10px;
  height: 10px;
  margin-right: 5px;
}

.ui.table tr td.statusWarning {
  position: relative;
  color: #feb62c;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
}

.ui.table tr td.statusWarning::before {
  display: inline-block;
  width: 10px;
  height: 10px;
  margin-right: 5px;
}
.ui.table tr td.statusActive {
  position: relative;
  color: #148baa;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
}

.ui.table tr td.statusActive::before {
  display: inline-block;
  width: 10px;
  height: 10px;
  margin-right: 5px;
}
.ui.sortable.stackable.table {
  border: 0 !important;
}

tr {
  vertical-align: baseline !important;
}
tr a {
  vertical-align: middle !important;
  color: var(--black, #101820);
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  text-decoration-line: underline;
}
.ui.selection.dropdown {
  padding: 0 !important;
}

.activeAddButton {
  width: 120px !important;
  height: 35px !important;
  border-radius: 100px !important;
  border: 1px solid var(--blue-1, #0082ca) !important;
  color: white !important;
  font-size: 13px !important;
  font-weight: 400 !important;
  line-height: 18px !important;
  background: var(--blue-1, #0082ca) !important;
  margin-left: 20px !important;
  padding: 1px 6px 1px 6px !important;
}

.ui.table thead tr:first-child > th:first-child,
.ui.table thead th.firstColumn {
  border-radius: 8px 0 0 8px;
}
.ui.table thead tr:first-child > th:last-child,
.ui.table thead th.lastColumn {
  border-radius: 0 8px 8px 0;
}
.ui.table tr td {
  color: var(--black, #101820);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
}
.addButton {
  width: 120px !important;
  height: 35px !important;
  border-radius: 100px !important;
  border: 1px solid var(--blue-1, #0082ca) !important;
  color: var(--blue-1, #0082ca) !important;
  font-size: 13px !important;
  font-weight: 400 !important;
  line-height: 18px !important;
  background: white !important;
  margin-left: 20px !important;
  padding: 1px 6px 1px 6px !important;
}
.ui.table thead th {
  width: 75px;
  height: 37px;
  background: #f3f6f9;
  color: var(--dark-grey, #7a7a7a) !important;
  font-size: 12px !important;
  font-weight: 500 !important;
  text-transform: uppercase !important;
  justify-content: center !important;
  border-left: 0 !important;
  border-bottom: 0 !important;
}
.content.active {
  color: white;
}

.image-container {
  position: relative !important;
}

.image-container img {
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
}

.alert-error {
  color: #ff2727 !important;
  font-size: 18px !important;
  font-weight: 100 !important;
  justify-content: center !important;
  background: transparent !important;
  width: 100% !important;
  display: flex !important;
  align-items: center;
}
.alert-success {
  color: #128210 !important;
  font-size: 18px !important;
  font-weight: 100 !important;
  justify-content: center !important;
  background: transparent !important;
  width: 100% !important;
  display: flex !important;
  align-items: center;
}

.ui.transparent.input > input {
  padding-left: 30px !important;
  font-size: large !important;
  font-family: unset !important;
  color: white !important;
  border-radius: 8px;
  border: 1px solid #687b8f;
}

.ui.grid > .row {
  padding-bottom: 0px;
}
.generateSNSegment {
  padding-bottom: 20px  !important;
}

.ui.bottom.attached.segment.active.tab {
  background: transparent;
  height: 100%;
  border: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.required:after,
.required.field > label:after {
  margin: -0.2em 0 0 0.2em;
  content: '*';
  color: #db2828;
}
.ui.sortable.table thead th:after{
  display: inline  !important;
  content: '\f0dc ';
}
.ui.sortable.table thead th.nonSortable:after{
  display: none  !important;
}
.ui.basic.red.label
{
  font-size: 10px !important;
  border: 0 !important;
}
.statusDeviceEncriptedActive
{
  cursor: pointer !important;
}
.statusDeviceEncriptedActive:hover
{
  filter: invert(30%) sepia(99%) saturate(1306%) hue-rotate(177deg) brightness(96%) contrast(104%)  !important;
}

.statusEncryptionFailed
{
  cursor: pointer !important;
  filter: invert(21%) sepia(96%) saturate(2301%) hue-rotate(349deg) brightness(95%) contrast(87%)  !important;
}
.modalCenter {
  position: absolute !important;
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%) !important;
    /* top | right | bottom | left */
    padding: 28px 32px 28px 32px !important;
    z-index: 1000; 
}
.modalTitle{
  color: var(--Black, #101820);
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
 line-height: normal;
}
.modalTitleL{
  color: var(--Black, #101820);
  font-size: 18px;
  font-weight: 600;

}
.modalSubTitle{
  color: var(--Black, #101820);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  display: block;
  margin-top: 3px;
}
.modulButton {

  color: var(--White, #FFF)  !important;
  text-align: center  !important;
  font-size: 14px  !important;
  font-weight: 600 !important;

  width: 100px;
  height: 41px !important;
  margin-left: 5px !important;
  border-radius: 6px  !important;
  background: var(--Black, #101820)  !important;

}
.ui[class*="left icon"].input>i.icon
{
  left :2px  !important;
  top:2px  !important;
}
 .mainPageGrid.modal-open
{
  opacity: 0.5;
  pointer-events: none  !important; 
  position: relative;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(16, 24, 32, 0.41)   !important;
  z-index: 999; 
}
.recoveryKeyList tr td 
{
  white-space: nowrap;
}
.modalHeader{
  border-radius: 6px;
  background: var(--Greys-Grey-4, #F3F6F9);
  display: inline-flex;
  height: 42px;
 padding: 8px 21px;
 align-items: center;
 gap: 10px;
 flex-shrink: 0;
 width: 100% !important;
 margin-top: 20px;
 font-weight: 600  !important;
}

.table.recoveryKeyList thead th
{
  color: var(--Brand-Style-Guide-Black-6-C, #101820) !important;
  font-size: 14px  !important;
  font-weight: 600  !important;
  background: transparent !important;
  text-transform:none !important;
}
.table.recoveryKeyList tr:first-child td
{
  border-top: 1px solid rgba(34, 36, 38, .1)  !important;
}
.inline-content {
  display: flex;
  align-items: center; /* Выровнять по вертикали */
}

.inline-content img {
  margin-right: 8px; /* Отступ между изображением и текстом, при необходимости */
}