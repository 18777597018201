
.ui.container {
  width: 85%;
  padding-left: 125px;
  padding-top: 50px;
}
.underMenu {
  height: 73px !important;
}
.ui.header {
  font-family: inherit;
}
.forRedirect
{
  position: absolute !important;
  top: 125px !important;
  height: 400px;
  width: 100% !important;
  text-align: center;
  background-image: url('/public/img/V1.55/back.jpg') !important; 
  color: white;
  font-size: large !important;
  font-weight: 500;
}
.ui[class*="vertically divided"].grid>.row>.column {
  margin-top: 0rem; 
  margin-bottom: 0rem;
  padding-top: 0;
  padding-bottom: 0;
  font-weight:bold;
}
.mainPageCard {
  padding-top: 60px !important;
  padding-left: 0px !important;
}
.mainMobilePageCard {
  margin-left: -6rem !important;
  margin-top: 2rem !important;
  padding-top: 0rem !important;
}

.CompanyCard {
  margin-left: -6rem !important;
  margin-top: 2rem !important;
  max-width: fit-content !important;
}
.gridMobileColumn {
  position: relative;
  display: inline-block;
  width: 100% !important;
  padding-left: 0rem !important;
  padding-right: 1rem;
  vertical-align: top;
}
.gridMobileColumn > .ui.segment {
  margin-top: 10px !important;
}
.gridColumn {
  vertical-align: top;
}
th {
  white-space: nowrap;
}
.collorSet1 {
  background-color: #008aab !important;
  color: white !important;
}
.statusAll {
  background-color: #b5b5b5 !important;
  color: white !important;
  text-align: center;
}

.ui.menu .active.item {
  font-weight: bold !important;
  color: #0069a7 !important;
}

.testMsg{
  font-weight: bold !important;
  color: #eb0455 !important;
  font-size:xx-large    !important;

 }
.blueItem {
  background-color: #008aab !important;
  color: white !important;
  text-align: center;
 
}

.ui.compact.table td {
  padding: 0.2em 0.2em;
  font-size: smaller;
}

.SNBox {
  background-color: #008aab !important;
  color: white !important;
  width: 70px !important;
}

.collorSet2 {
  background-color: #0069a7 !important;
}
.collorSet3 {
  background-color: #00b2e3 !important;
  color: white !important;
}
.collorSet4 {
  background-color: #54c8e8 !important;
  color: white !important;
}
.ui.teal.compact.segment {
  background-color: white !important;
  border-top: 3px solid #0082ca !important;
  color: #0082ca !important;
}

.ui.table td.positive,
.ui.table tr.positive {
  background: #eff8fa !important;
  color: #0069a7 !important;
}
.ui.selectable.table tr.positive:hover,
.ui.selectable.table tr:hover td.positive,
.ui.table tr td.selectable.positive:hover {
  background: #c1f0fb !important;
  color: #0069a7 !important;
}

.ui.form .field.field input:-webkit-autofill:focus {
  box-shadow: 0 0 0 100px #f2f2f4 inset !important;
  border-color: #54c8e8 !important;
}

.ui.form .field.field input:-webkit-autofill {
  box-shadow: 0 0 0 100px #f2f2f4 inset !important;
  border-color: #54c8e8 !important;
}

.titleSet1 {
  color: #0069a7 !important;
}

.SalvadorHeader1 {
  color: #0082ca !important;
  font-weight: lighter !important;
}
.salvadorHeaderh4 {
  color: #0082ca !important;
  font-weight: bold;
}

.ui.tabular.menu .item {
  color: white !important;
}
.ui.tabular.menu .active.item {
  color: #0082ca !important;
}
.statusClass {
  overflow-wrap: break-word;
  text-align: center;
}
.alert-success {
  background-color: white !important;
  color: teal !important;
  text-align: center;
  white-space: pre-line;
}
.thirteen.wide.column,
.seven.wide.column,
.fourteen.wide.column,
.two.wide.column {
  padding-left: 0px !important;
}
.clearFilter {
  margin-bottom: 0px !important;
  cursor: pointer !important;
  color: #0069a7 !important;
}

.alert-error {
  background-color: red !important;
  color: white !important;
  text-align: center;
}
.recharts-legend-item-text {
  color: black !important;
  font-weight: bold;
  font-size: medium;
}
.dowloadBtn {
  position: absolute !important;
  right: -0 !important;
}
.redItem {
  background-color: #101820 !important;
  color: white !important;
  text-align: center;
}
.orangeItem {
  background-color: #bcbcbc !important;
  color: white !important;
  text-align: center;
}
.searchInput {
  min-width: 9em !important;
}
.ui.icon.input > input {
  padding-right: 0 !important;
}
.ui.small.header {
  margin-top: 0px;
}
.mail.icon {
  color: rgba(255, 255, 255, 0.9) !important;
}
.geeyItem {
  background-color: #96daea !important;
  color: white !important;
  text-align: center;
}
.contactUsMenu {
  font-size: small !important;
  font-style: oblique;
  position: absolute !important;
  bottom: 0;
}
.contactUsIcon {
  float: none !important;
  padding-left: 0px !important;
}
.searchInput {
  min-width: 10em !important;
}
path {
  cursor: pointer;
}

.ActiveIcon {
  cursor: pointer;
}
.ui.red.ribbon.label{
  cursor: default;
}
.searchInput > input {
  padding-right: 0 !important;
  max-width: 8em !important;
}
.userGuideSabTitle {
  font-weight: bold;
  color: teal;
  font-size: initial;
}

.ui.sub.header {
  text-transform:none;
}

.purpleItem {
  background-color: purple !important;
  color: white !important;
  text-align: center;
}

.oliveItem {
  background-color: olive !important;
  color: white !important;
  text-align: center;
}
.tealItem {
  background-color: #00b2e3 !important;
  color: white !important;
  text-align: center;
}
.uploadType {
  font-size: medium !important;
  margin-bottom: 10px !important;
}
.greenItem {
  background-color: #00567d !important;
  color: white !important;
  text-align: center;
}
.adminArea {
  color: rgb(184, 195, 196) !important;
  text-align: center;
  font-size: smaller;
  height: 5px;
}
.compDesc {
  font-weight: bold;
  font-size: smaller;
  color: rgb(33, 138, 174);
}

.CriticalErrorItem {
  color: red !important;
  font-weight: bold;
}

.WarningErrorItem {
  color: orange !important;
  font-weight: bold;
}

.SuccessItem {
  color: teal !important;
  font-weight: bold;
}
.ui.teal.compact.segment {
  background-color: rgb(228, 226, 226);
}

.fixedTopMenu {
  background-image: linear-gradient(
    135deg,
    #101820 0%,
    #101820 30%,
    #008aab 89%
  ) !important;
}
.ui.multiple.search.dropdown {
  max-width: 600px !important;
}
.fixedTopMenuMobile {
  background-color: black !important;
}
.ActiveButton {
  background-color: #00b2e3 !important;
  color: white !important;
  width: 150px;
  height: 35px;
}
.nonActiveButton {
  background-color: #00567d !important;
  color: white !important;
  width: 150px;
  height: 35px;
}
.sideIcon {
  margin: 0px !important;
}
.ui.fluid.card {
  margin-top: 4em !important;
}
.ui.large.vertical.menu {
  margin-left: 5px !important;
  margin-right: 5px;
}
.ui.secondary.button {
  margin-left: 4em !important;
}

.ui.vertical.menu .item > i.icon {
  float: right;
  margin: 0 0 0 0;
  padding-right: 5px !important;
}

.ui.vertical.menu .item {
  font-size: initial;
}

.filterItem {
  padding-left: 6px !important;
  padding-right: 1px !important;
  text-align: center;
}

.menuMobile {
  width: 3rem !important;
  padding-bottom: 20px;
}

.menuMobile .item {
  padding-bottom: 20px !important;
}

.navMenu {
  width: auto !important;

  background-color: black;
}
.containerMobile {
  margin-top: 5em !important;
  padding-left: 25px !important;
}
.formSN {
  background: #f3f4f5;
  color: rgba(0, 0, 0, 0.6);
  box-shadow: 0 2px 4px 0 rgb(34 36 38 / 12%), 0 2px 10px 0 rgb(34 36 38 / 15%);
  padding: 2em;
  display: table;
}

.containerMobile .ui.grid {
  margin-left: 20px !important;
}
.mobailSegment {
  margin-left: 20px !important;
}
.mobailNavButton {
  margin-left: 35px !important;
}

.mobailImage {
  width: 80px !important;
}
.mobailSegmentLebel {
  margin-left: 10px !important;
}
/*home page styles*/
.ui.disabled.input {
  opacity: inherit !important;
}
.mainLogo
{
  height: 100px;
  align-self:center;
  display: inline !important;
  top: 15px !important; 
}
.masthead {
  align-items: center;
  background-image: url('/public/img/V1.55/back.jpg') !important; 
   height: 102vh; 
}
/* .mobailTab {
  background-image: url('/img/back.jpg') !important; 
} */
.startPageBady
{
  position: absolute !important;  
  top: 140px !important; 
}
.startPageBottom
{
  position: absolute !important;  
  bottom: 15px !important; 
  left: 0 !important; 
  height: 100px !important; 
}

.closeImage
{
  position: absolute !important;  
  top: 0 !important; 
  right:  0 !important; 
 cursor: pointer;
}
.internalForm {
  width: 100% !important;
}

.ui.form .fields > .field:first-child {
  width: 100%;
}

.ui.teal.right.ribbon.label {
  float: none !important;
  padding-right: inherit;
}
.UserCardsList {
  margin-left: 10px !important;
}
.ui.form.approvePassword {
  background-color: white;
}
.ui.blue.large.basic.label {
  margin-top: 1em !important;
}
.masthead .ui.grid {
  display: contents;
}
.masthead .ui.menu .ui.button,
.ui.menu a.ui.inverted.button {
  margin-left: 0.5em;
}

.masthead h1.ui.header {
  font-size: 4em;
  font-weight: normal;
}

.masthead h2 {
  font-size: 1.7em;
  font-weight: normal;
}

.bottomLinks {
  font-size: large  !important;
  font-weight: normal;
  color: white;
}
.bottomLinksImages :hover {
  border-color: white;
  border-style: solid;
}
.LinksImagesCol {
 width: 60px !important;
padding: 0  !important;
}
.versionMobile
{
  font-size: smaller;
  font-style: initial;
  font-weight: bold;
}
.menuIconMobile
{
  padding-left: 10px !important;
  padding-right: 10px !important;
}

.ui.button.labeled.multiple.search.selection.scrolling.dropdown {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
.notPadding {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.ui.grid .eight.wide {
  width: 100% !important;
}

.assetStatus1,.reportStatus5
{
   color: green !important;
}
.assetStatus2,.reportStatus2
{
  color: orange !important;
}

.assetStatus3, .reportStatus6
{
  color: red !important;
}
.assetStatus11,.reportStatus4,.reportStatus3,.reportStatus1
{
  color: grey !important;
  white-space: nowrap  !important;
}
.statusButton
{
  display: flex !important;
}

/*end home page styles*/
