@font-face {
    font-family: 'Montserrat';
    src: url('/public/fonts/Montserrat-Regular.ttf') format('truetype');
  }
  
  * {
    font-size: 100%;
    font-family: Montserrat;
    font-style: normal;
    line-height: normal;
    outline: none !important;
  }
  body {
    background-repeat: round;
    background-size: contain;
 }
 .accordionEnter {
    display: flex !important;
    align-items: center !important;
    pointer-events: none !important;
  }
  .accordionList {
    height: 40px;
  }
  .accordionList img {
    width: 22px;
    height: 22px;
    margin-right: 10px !important;
  }
  .accordionList a {
    color: white;
    font-size: 16px;
    padding: 5px;
  }
  .accordionImg {
    width: 20px;
    height: 20px;
    margin-top: 10px;
    margin-right: 3px;
  }
  .dots {
    color: white;
    font-size: 44px;
    pointer-events: none !important;
  }


  .startPage {
    display: grid;
    height: 101vh;
    width: 100vw;
    justify-content: center !important;
    background: linear-gradient(90deg, #071d29 0%, #071e2b 100%),
      url('/public/img/Space.jpg');

    background-size: cover;
    background-blend-mode: lighten;
    box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.25);
  }


  .startPageGrid {
    width: 75% !important;
    height: 90% !important;
    margin-top: 2% !important;
    vertical-align: bottom;
    justify-content: center !important;
  }
  
  .startPageTabGrid {
    border-radius: 20px;
    background: #002437;
    box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.25);
    width: 90% !important;
    height: 85% !important;
    display: grid !important;
    grid-template-rows: 90% 10%;
  }
  .startPageTabRow {
    justify-content: center;
  }
  .startPageTabRow > .active.item {
    color: var(--Grey, #f3f6f9) !important;
    font-size: 20px !important;
    font-weight: 700 !important;
  }
  .startPageTabRow > .item {
    color: var(--Dark-Grey, #7a7a7a) !important;
    font-size: 20px !important;
    font-weight: 400 !important;
  }
  
  .startPageTabLabel {
    color: var(--Grey, #f3f6f9) !important;
    font-size: 1rem !important;
    font-weight: 400 !important;
  }
  
  .loginField {
    background-color: white;
    box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
    border: 1px solid #687b8f;
    width: 100%;
    height: 100%;
    padding-top: 3px;
    padding-bottom: 3px;
    margin-top: 10px;
  }
  .loginRow {
    height: 33% !important;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .loginRow1 {
    height: 15% !important;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  
.loginBoxRow {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  
  .loginBoxBottom {
    border-radius: 0px 0px 20px 20px;
    background-color: rgba(0, 86, 125, 0.2);
    box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.25);
    padding-top: 2vh;
  }
  
  .loginGrid {
    height: 85% !important;
    width: 100% !important;
    padding-top: 20px;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    width: min-content;
    box-sizing: border-box;
  }

  .loginErrorLbl {
    color: #FF2727 !important;
    text-align: center;
    align-items: center;
    font-size: 14px !important;
    font-weight: 400;
    background: transparent !important;
   }
   .loginResponseMessage{
    text-align: center;
    align-items: center;
    font-size: 14px !important;
    font-weight: 400;
    position: absolute !important;
    top: -10px !important;
    background: transparent !important;
   }
   .fail
   {
    color: #FF2727 !important;
   }
   .success{
    color: #3dc03a !important;
   }
   .loginRemark {
    color: var(--Grey, #f3f6f9);
    text-align: center;
    font-size: 16px;
    font-weight: 400;
  }
  .loginRemarkBold {
    font-size: 16px;
    font-weight: 700;
    color: inherit !important;
    cursor: pointer !important;
  }
  .loginGroupLabel {
    color: var(--Grey, #f3f6f9);
    font-size: 16px;
    font-weight: 700;
  }
  .groupSmalImg {
    display: flex !important;
    justify-content: center !important;
  }
  .justifyCenter {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
  }
  .fullHeight > div {
    height: 100% !important;
  }
  .row.loginRow > .column > .field {
    height: 45%;
  }
  
  .vertical-form {
    display: flex;
    height: 85%;
    width: 75%;
    flex-direction: column;
    justify-content: space-between;
    background: transparent;
    box-sizing: border-box;
  }
  .vertical-form > div,
  .vertical-form1 > div {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  
  .vertical-form1 {
    display: flex;
    height: 100%;
    width: 100%;
    flex-direction: column;
    justify-content: space-between !important;
    background: transparent;
    box-sizing: border-box;
  }

  .loginBtn {
    border-radius: 100px !important;
    background: var(--blue-1, #0082ca) !important;
    width: auto !important;
    height: 40px !important;
    color: #f3f6f9 !important;
    text-align: center !important;
    font-size: 16px !important;
    font-weight: 700 !important;
  }
  .mediumImg {
    width: 42px;
    height: 42px;
    margin-right: 10px;
  }
  .blueRemark {
    color: var(--blue-4, #96daea) !important;
    text-align: center !important;
    font-size: 14px !important;
    font-weight: 400 !important;
    background: transparent !important;
    justify-content: center;
  }
  .questImg {
    width: 20px;
    height: 20px;
    position: absolute !important;
    top: 20px;
    right: 50px;
    transform: translateY(-50%);
  }
  .smallTitleRow {
    justify-content: center !important;
    height: 10%;
  }
  .smallTitleRow1 {
    height: 5%;
    padding-left: 14px !important;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .smallImg {
    width: 24px;
    height: 24px;
    margin-right: 10px !important;
  }
  .divider {
    flex-grow: 1;
    height: 1px;
    margin: 0 10px;
    margin-top: 10px;
  }
  .ui.divider {
    width: '100%' !important;
    height: 1px !important;
    background: #e4e4e4 !important;
  }  